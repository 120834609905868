@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");
@import url("https://fonts.googleapis.com/css?family=VT323");


.logo {
  text-align: left;
  font-family: "Press Start 2P", "VT323";
  color: black;
}

html, body{
  height:100%;
  position:fixed;
  background-color: #ffffff;
  
}
body{
  overflow:hidden;
}

.markdown img {
  width: 300px;
  height: 200px;
}

/* #E3E5E7 */





/* 
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{ font-size: 25px !important;}


.ant-menu-inline > .ant-menu-item{ font-size: 25px !important;} */

