@keyframes jelly {
    0%, 100% {
      transform: scaleX(1) scaleY(1);
      transform-origin: 50% 50%;
    }
    20%, 80% {
      transform: scaleX(1.05) scaleY(0.95);
    }
    40%, 60% {
      transform: scaleX(0.95) scaleY(1.05);
    }
  }


  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes jellyEffect {
    0%, 100% {
      transform: scale(1, 1);
    }
    30% {
      transform: scale(1.1, 0.9);
    }
    50% {
      transform: scale(0.95, 1.05);
    }
    70% {
      transform: scale(1.05, 0.95);
    }
    90% {
      transform: scale(0.975, 1.025);
    }
  }

  @keyframes bounceEffect {
    0%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-5px); /* 向上跳30px */
    }
  }